import { SvgIcon, Link, Box } from "@mui/material";
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";
import { ReactComponent as TelegramChannel } from "../../assets/icons/telegram-channel.svg";
import { ReactComponent as Github } from "../../assets/icons/github.svg";
// import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import MediumMobile from "../../assets/icons/Medium_mobile.png";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";
import { ReactComponent as Youtube } from "../../assets/icons/YouTube.svg";
import { ReactComponent as Certik } from "../../assets/icons/certik.svg";
import { ReactComponent as Email } from "../../assets/icons/email.svg";
import { ReactComponent as SocialIcon } from "../../assets/icons/aa.svg";
import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Social() {
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  return (
    <Box
      display="flex"
      justifyContent={"space-between"}
      alignItems="center"
      sx={{ width: "100%", maxWidth: "245px" }}
    >
      {/* <Link href="" target="_blank">
        <SvgIcon color="primary" component={Github} />
      </Link> */}
      <Link
        href="https://x.com/NovaBank0"
        target="_blank"
        sx={{
          color: "white",
        }}
      >
        <SvgIcon
          sx={{
            "&:hover": {
              color: "#FCD000",
            },
            width: { sm: "32px", xs: "24px" },
            height: { sm: "32px", xs: "24px" },
          }}
          viewBox="0 0 24 24"
          component={Twitter}
        />
      </Link>

      <Link
        href="https://t.me/NovaBankChat"
        target="_blank"
        sx={{
          color: "white",
        }}
      >
        <SvgIcon
          sx={{
            "&:hover": {
              color: "#FCD000",
            },
            width: { sm: "32px", xs: "24px" },
            height: { sm: "32px", xs: "24px" },
          }}
          viewBox="0 0 24 24"
          component={Telegram}
        />
      </Link>
      <Link
        href="https://t.me/NovaBankAnnouncement"
        target="_blank"
        sx={{
          color: "white",
        }}
      >
        <SvgIcon
          sx={{
            "&:hover": {
              color: "#FCD000",
            },
            width: { sm: "32px", xs: "24px" },
            height: { sm: "32px", xs: "24px" },
          }}
          viewBox="0 0 24 24"
          component={TelegramChannel}
        />
      </Link>
      {/* <Link href="" target="_blank">
        <SvgIcon color="primary" component={Medium} />
      </Link> */}

      <Link
        href="https://medium.com/@novabank"
        target="_blank"
        sx={{
          color: "white",
        }}
      >
        <SvgIcon
          sx={{
            "&:hover": {
              color: "#FCD000",
            },
            width: { sm: "32px", xs: "24px" },
            height: { sm: "32px", xs: "24px" },
          }}
          viewBox="0 0 24 24"
          component={Medium}
        />
      </Link>
      <Link
        href="https://www.youtube.com/@NovaBankOfficial"
        target="_blank"
        sx={{
          color: "white",
        }}
      >
        <SvgIcon
          sx={{
            "&:hover": {
              color: "#FCD000",
            },
            width: { sm: "32px", xs: "24px" },
            height: { sm: "32px", xs: "24px" },
          }}
          viewBox="0 0 24 24"
          component={Youtube}
        />
      </Link>
      <Link
        href="https://skynet.certik.com/projects/nova-bank#active-monitor"
        // href="https://www.youtube.com/@NovaBankOfficial"
        target="_blank"
        sx={{
          color: "white",
        }}
      >
        <SvgIcon
          sx={{
            "&:hover": {
              color: "#FCD000",
            },
            width: { sm: "32px", xs: "24px" },
            height: { sm: "32px", xs: "24px" },
          }}
          viewBox="0 0 24 24"
          component={Certik}
        />
      </Link>
      {/* <Link
        href="mailto:novabank.bond@gmail.com"
        target="_blank"
        sx={{
          color: "white",
        }}
      >
        <SvgIcon
          sx={{
            "&:hover": {
              color: "#FCD000",
            },
          }}
          component={Email}
        />
      </Link> */}
    </Box>
  );
}
