import {
  Typography,
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
} from "@mui/material";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { t, Trans } from "@lingui/macro";
import "./bondSettings.scss";
import { useMobile } from "../../hooks";

function AdvancedSettings({
  open,
  handleClose,
  slippage,
  recipientAddress,
  onRecipientAddressChange,
  onSlippageChange,
  onSlippageBlur,
  theme,
  isBtn,
}) {
  const { isSmallScreen } = useMobile();

  return (
    <Modal
      id="hades"
      open={open}
      onClose={handleClose}
      hideBackdrop
      sx={{
        px: isSmallScreen ? 1.875 : 0,
      }}
    >
      <Paper
        className="ohm-card ohm-popover"
        sx={{
          borderRadius: { sm: "10px", xs: "6px" },
          border: "1px solid #2D2D2D",
          background: "#151515",
          px: isSmallScreen ? 2.5 : 3.75,
          py: 2.5,
        }}
      >
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              color: "#EAECEF",
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            <Trans>Transaction Settings</Trans>
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              p: 0,
            }}
          >
            <SvgIcon sx={{ color: "#EAECEF" }} component={XIcon} />
          </IconButton>
        </Box>

        <Box className="card-content">
          <InputLabel
            htmlFor="slippage"
            sx={{
              color: "#FFF",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
            }}
          >
            <Trans>Slippage</Trans>
          </InputLabel>
          <FormControl variant="outlined" color="primary" fullWidth>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              className="slippage-container"
            >
              <Button
                variant="contained"
                className={`slippage-item`}
                onClick={() => onSlippageChange("0.1", "btn")}
                style={{
                  color: "#FCD000",
                  borderRadius: "6px",
                  maxWidth: isSmallScreen ? "65px" : "98px",
                  height: isSmallScreen ? "25px" : "40px",
                  fontSize: isSmallScreen ? "12px" : "16px",
                  width: "100%",
                  background: "#151515",
                  border:
                    isBtn == "btn" && slippage == "0.1"
                      ? "1px solid #fcd000"
                      : "1px solid #2D2D2D",
                }}
              >
                0.1%
              </Button>
              <Button
                variant="contained"
                className="slippage-item"
                onClick={() => onSlippageChange("0.5", "btn")}
                style={{
                  color: "#FCD000",
                  borderRadius: "6px",
                  width: "100%",
                  maxWidth: isSmallScreen ? "65px" : "98px",
                  height: isSmallScreen ? "25px" : "40px",
                  fontSize: isSmallScreen ? "12px" : "16px",
                  background: "#151515",
                  border:
                    isBtn == "btn" && slippage == "0.5"
                      ? "1px solid #fcd000"
                      : "1px solid #2D2D2D",
                }}
              >
                0.5%
              </Button>
              <Button
                variant="contained"
                className="slippage-item"
                onClick={() => onSlippageChange("1.0", "btn")}
                style={{
                  color: "#FCD000",
                  borderRadius: "6px",
                  width: "100%",
                  maxWidth: isSmallScreen ? "65px" : "98px",
                  height: isSmallScreen ? "25px" : "40px",
                  fontSize: isSmallScreen ? "12px" : "16px",
                  background: "#151515",
                  border:
                    isBtn == "btn" && slippage == "1.0"
                      ? "1px solid #fcd000"
                      : "1px solid #2D2D2D",
                }}
              >
                1.0%
              </Button>
              <OutlinedInput
                id="slippage"
                value={slippage}
                onChange={(e) => onSlippageChange(e.target.value, "ipt")}
                onBlur={(e) => onSlippageBlur(e.target.value)}
                type="number"
                // max="100"
                // min="0"
                className="slippage-ipt"
                sx={{
                  marginRight: isSmallScreen ? "0" : "12px",
                  pr: isSmallScreen ? "4px" : "14px",
                  height: isSmallScreen ? "25px" : "40px",
                  color: "#fcd000",
                  textAlign: "center",
                  borderRadius: "6px",
                  border: "1px solid #2d2d2d",
                  background: "#1e1e1e",
                  "& > input": {
                    fontSize: isSmallScreen ? "12px !important" : "16px",
                  },
                }}
                endAdornment={
                  <InputAdornment
                    className="last-icon"
                    position="end"
                    sx={{
                      "& > p": {
                        color: "#fcd000",
                      },
                    }}
                  >
                    %
                  </InputAdornment>
                }
              />
              <Typography
                sx={{
                  color: "#fcd000",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: "700",
                }}
                className={`slippage-precent`}
              >
                %
              </Typography>
            </Box>
            <div className="help-text">
              <Typography
                sx={{
                  color: "#848E9C",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}
              >
                <Trans>
                  If the price changes by more than the slippage percentage,
                  trading may resume
                </Trans>
              </Typography>
            </div>
          </FormControl>
        </Box>
      </Paper>
    </Modal>
  );
}

export default AdvancedSettings;
