import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  Typography,
} from "@mui/material";
import {
  customNumberFormat,
  prettifySeconds,
  secondsUntilBlock,
  shorten,
  trim,
} from "../../helpers";
import {
  bondAsset,
  calcBondDetails,
  changeApproval,
  setBondState,
} from "../../slices/BondSlice";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@mui/material";
import useDebounce from "../../hooks/Debounce";
import { error } from "../../slices/MessagesSlice";
import { DisplayBondDiscount } from "./Bond.jsx";
import ConnectButton from "../../components/ConnectButton";
import DownIcon from "../../assets/icons/down.png";
import "./bondPurchase.scss";
import { useMobile } from "../../hooks";
import { LoadingButton } from "@mui/lab";

function BondPurchase({ bond, slippage, recipientAddress, theme }) {
  const { isSmallScreen } = useMobile();
  const isLight = theme === "light" ? true : false;
  const SECONDS_TO_REFRESH = 60;
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  const [isShowSelect, changeShowSelect] = useState(false);
  const [selVal, setSelVal] = useState(0);
  const [tokenVal, setTokenVal] = useState(bond.displayName);
  const [quantity, setQuantity] = useState("");
  const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);
  const currentBlock = useSelector((state) => {
    return state.app.currentBlock;
  });
  useEffect(() => {
    setTokenVal(bond.name === "nvb-usdt_lp" ? "USDT" : bond.displayName);
  }, [bond.name]);
  const isBondLoading = useSelector((state) => state.bonding.loading ?? true);

  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    const vestingBlock = parseInt(currentBlock) + parseInt(bond.vestingTerm);
    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    return prettifySeconds(seconds, "day");
  };

  async function onBond() {
    // console.log(
    //   "bondAsset",
    //   quantity > bond[0].balance[selVal],
    //   quantity,
    //   bond[0].balance
    // );
    if (quantity === "") {
      dispatch(error(t`Please enter a value!`));
      return;
      // } else if (isNaN(quantity) || quantity <= 0 || quantity > maxQ) {
    } else if (isNaN(quantity) || quantity <= 0) {
      dispatch(error(t`Please enter a valid value!`));
      return;
    } else if (quantity > Number(bond[0].balance[selVal])) {
      dispatch(error(t`You don't have enough Balance!`));
      return;
    } else if (bond.bondQuote > bond.maxBondPrice) {
      dispatch(
        error(
          `${t`You're trying to bond more than the maximum payout available! The maximum bond payout is`} ${trim(
            bond.maxBondPrice,
            4
          )} NVB`
        )
      );
      return;
    } else if (bond.interestDue > 0 || bond.pendingPayout > 0) {
      const shouldProceed = window.confirm(
        t`You have an existing bond. Bonding will reset your vesting period and forfeit rewards. We recommend claiming rewards first or using a fresh wallet. Do you still want to proceed?`
      );
      if (shouldProceed) {
        await dispatch(
          bondAsset({
            value: quantity,
            slippage,
            bond,
            networkID: chainID,
            provider,
            selVal,
            address: recipientAddress || address,
          })
        );
      }
    } else {
      console.log("bondAsset dispatch");
      await dispatch(
        bondAsset({
          value: quantity,
          slippage,
          bond,
          networkID: chainID,
          provider,
          selVal,
          address: recipientAddress || address,
        })
      );
      clearInput();
      // dispatch(
      //   calcBondDetails({
      //     bond,
      //     value: 0,
      //     provider,
      //     networkID: chainID,
      //     selVal,
      //   })
      // );
    }
  }

  const clearInput = () => {
    setQuantity(0);
  };
  console.log("bond", bond);
  const setMax = () => {
    console.log("bond max", bond, selVal);
    let maxQ;
    if (Number(selVal) <= 1) {
      if (bond.name !== "nvb-usdt_lp" && bond.name !== "usdt") {
        console.log(
          "bond.maxBondPrice * bond.bondPrice < Number(bond[0].balance[selVal]) * bond.tokenPrice",
          bond.maxBondPrice * bond.bondPrice <
            Number(bond[0].balance[selVal]) * bond.tokenPrice
        );
        if (
          bond.maxBondPrice * bond.bondPrice <
          Number(bond[0].balance[selVal]) * bond.tokenPrice
        ) {
          // there is precision loss here on Number(bond.balance)
          maxQ = (bond.maxBondPrice * bond.bondPrice) / bond.tokenPrice;
        } else {
          maxQ = bond[0].balance[selVal];
        }
      } else {
        if (
          bond.maxBondPrice * bond.bondPrice <
          Number(bond[0].balance[selVal])
        ) {
          // there is precision loss here on Number(bond.balance)
          maxQ = bond.maxBondPrice * bond.bondPrice.toString();
        } else {
          maxQ = bond[0].balance[selVal];
        }
      }
    } else {
      if (bond.maxBondPrice * bond.bondPrice < Number(balances[selVal - 2])) {
        // there is precision loss here on Number(bond.balance)
        maxQ = bond.maxBondPrice * bond.bondPrice.toString();
      } else {
        maxQ = balances[selVal - 2];
      }
    }
    console.log("maxQ:", maxQ);
    setQuantity(trim(maxQ.toString(), 4));
    // setQuantity(maxQ.toString());
  };

  const bondDetailsDebounce = useDebounce(quantity, 500);

  useEffect(() => {
    dispatch(
      calcBondDetails({
        bond,
        value: quantity,
        provider,
        networkID: chainID,
        selVal,
      })
    );
  }, [bondDetailsDebounce]);

  useEffect(() => {
    let interval = null;
    if (secondsToRefresh > 0) {
      interval = setInterval(() => {
        setSecondsToRefresh((secondsToRefresh) => secondsToRefresh - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      dispatch(
        calcBondDetails({
          bond,
          value: quantity,
          provider,
          networkID: chainID,
          selVal,
        })
      );
      setSecondsToRefresh(SECONDS_TO_REFRESH);
    }
    return () => clearInterval(interval);
  }, [secondsToRefresh, quantity]);

  const onSeekApproval = async () => {
    dispatch(
      changeApproval({ address, bond, provider, networkID: chainID, selVal })
    );
  };

  const displayUnits = bond.displayUnits;
  let isAllowanceDataLoading;
  // console.log(
  //   "[calculateUserBondDetails isAllowanceDataLoading]",
  //   bond,
  //   bond.allowance == null
  // );
  if (typeof bond == "object" && bond[0]) {
    isAllowanceDataLoading = bond[0].allowance == null;
  } else {
    isAllowanceDataLoading = bond.allowance == null;
  }

  const hasAllowance = useCallback(() => {
    if (typeof bond == "object" && bond[0]) {
      return !bond[0].allowance ? false : bond[0].allowance[selVal] > 0;
    } else {
      return !bond.allowance ? false : bond.allowance[selVal] > 0;
    }
  }, [selVal]);

  const handleOnchange = (event) => {
    let value = event.target.value.replace(/[^\d.]/g, "");
    if (value < 0) {
      setQuantity("");
      return;
    }
    setQuantity(value);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection={!address || isAllowanceDataLoading ? "column" : "row"}
        justifyContent="space-around"
        alignItems={
          !address || isAllowanceDataLoading ? "center" : "flex-start"
        }
        flexWrap="wrap"
      >
        {!address ? (
          <Box
            sx={{
              width: "100%",
            }}
            display="flex"
            justifyContent={"center"}
          >
            <ConnectButton />
          </Box>
        ) : (
          <>
            {isAllowanceDataLoading ? (
              <Skeleton width="200px" />
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={isSmallScreen ? "column" : "row"}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <FormControl
                  className="ohm-input"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{
                    height: "44px",
                    m: "0px !important",
                    mr: "15px !important",
                    mb: isSmallScreen ? "20px !important" : 0,
                  }}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-amount"
                    sx={{
                      top: "-7px",
                      fontSize: "16px",
                      color: "#EAECEF",
                      fontWeight: "400",
                      "&.Mui-focused": {
                        color: "#EAECEF",
                      },
                    }}
                  >
                    <Trans>Amount</Trans>
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    type="number"
                    sx={{
                      width: "100%",
                      height: "44px",
                      borderRadius: "6px",
                      border: "1px solid #2d2d2d",
                      background: "#0c0c0c",
                      color: "#fff",
                      '& input[type="number"]': {
                        py: 0,
                      },
                    }}
                    value={quantity}
                    min={0}
                    onChange={handleOnchange}
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          variant="text"
                          onClick={setMax}
                          sx={{
                            color: "#FCD000",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          <Trans>Max</Trans>
                        </Button>
                        <Box className="selectBox">
                          <Box
                            display="flex"
                            className="selectVal"
                            // onClick={handlerSelect}
                          >
                            <Typography
                              sx={{
                                color: "#EAECEF",
                                fontSize: "16px",
                                fontWeight: "400",
                              }}
                            >
                              {tokenVal}
                            </Typography>
                          </Box>
                          {/* <Box
                            className={[
                              "selectList",
                              isShowSelect ? "show" : "",
                              !isLight ? "selectListDark" : "",
                            ].join(" ")}
                          >
                            <Box
                              className="selectItem"
                              onClick={() => {
                                handleChange(
                                  0,
                                  bond.name !== "nvb-usdt_lp"
                                    ? bond.displayName
                                    : "DAI"
                                );
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#EAECEF",
                                  fontFamily: "SF Pro Display",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }}
                              >
                                {bond.name !== "nvb-usdt_lp"
                                  ? bond.displayName
                                  : "DAI"}
                              </Typography>
                            </Box>
                            {bond.name !== "nvb-usdt_lp" ? (
                              ""
                            ) : (
                              <Box>
                                <Box
                                  className="selectItem"
                                  onClick={() => {
                                    handleChange(1, "USDT");
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#EAECEF",
                                      fontFamily: "SF Pro Display",
                                      fontSize: "16px",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      lineHeight: "normal",
                                    }}
                                  >
                                    USDT
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </Box> */}
                        </Box>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {/* )} */}
                {!bond.isAvailable[chainID] ? (
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "240px" },
                      height: "40px",
                      borderRadius: "6px",
                      border: "1px solid #000",
                      background: "#fcd000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      id="bond-btn"
                      className="main-btn transaction-button"
                      disabled={true}
                    >
                      <Trans>Sold Out</Trans>
                    </Button>
                  </Box>
                ) : isAllowanceDataLoading ? (
                  <Skeleton width="200px" />
                ) : hasAllowance() ? (
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "240px" },
                      height: "40px",
                      borderRadius: "6px",
                      border: "1px solid #000",
                      background: "#fcd000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        color: "#000",
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      disabled={
                        isPendingTxn(
                          pendingTransactions,
                          "bond_" + bond.name
                        ) || Number(quantity) == 0
                      }
                      loading={isPendingTxn(
                        pendingTransactions,
                        "bond_" + bond.name
                      )}
                      loadingPosition="end"
                      onClick={onBond}
                    >
                      {txnButtonText(
                        pendingTransactions,
                        "bond_" + bond.name,
                        t`Buy_Bond`
                      )}
                    </LoadingButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "240px" },
                      height: "40px",
                      borderRadius: "6px",
                      border: "1px solid #000",
                      background: "#fcd000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        color: "#000",
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      // id="bond-approve-btn"
                      // className="transaction-button"
                      disabled={isPendingTxn(
                        pendingTransactions,
                        "approve_" + bond.name
                      )}
                      loading={isPendingTxn(
                        pendingTransactions,
                        "approve_" + bond.name
                      )}
                      loadingPosition="end"
                      onClick={onSeekApproval}
                    >
                      {txnButtonText(
                        pendingTransactions,
                        "approve_" + bond.name,
                        t`Approve`
                      )}
                    </LoadingButton>
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
        {!hasAllowance() && (
          <Box sx={{ my: "10px", width: "100%" }}>
            <em>
              {bond.name !== "nvb-usdt_lp" ? (
                <Typography
                  sx={{
                    color: "#868b8f",
                    fontSize: "14px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                >
                  <Trans>Note:</Trans> <br />
                  <Trans>1.First time bonding</Trans> <b>{bond.displayName}</b>?{" "}
                  <br /> <Trans>2.Please approve to use your</Trans>{" "}
                  <b>{bond.displayName}</b> <Trans>for bonding</Trans>.
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#868b8f",
                    fontSize: "14px",
                    fontWeight: "400",
                    width: "100%",
                  }}
                >
                  <Trans>Note:</Trans>
                  <br />
                  <Trans>
                    1. Use USDT to create LP with one click. you do not need to
                    create LP manually.
                  </Trans>
                  <br />
                  <Trans>
                    2. Approval of the Transaction is only required during the
                    First binding; subsequent bindings only need you to perform
                    the binding transaction.
                  </Trans>
                </Typography>
              )}
            </em>
          </Box>
        )}
      </Box>

      <Slide
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
        {...{ timeout: 533 }}
      >
        <Box className="bond-data">
          <div className="data-row">
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Trans>Your Balance</Trans>
            </Typography>{" "}
            <Typography
              id="bond-balance"
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {/* {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                <>
                  {trim(bond.balance, 4)} {displayUnits}
                </>
              )} */}
              {isBondLoading && (!bond[0] || !bond.balance) ? (
                <Skeleton width="100px" />
              ) : (
                <>
                  {bond[0] && bond[0].balance
                    ? customNumberFormat(Number(bond[0].balance[selVal]), 2)
                    : 0}
                  {bond.name === "nvb-usdt_lp" ? "USDT" : bond.bondToken}

                  {/* {!bond.balance ? 0 : trim(bond.balance[selVal], 4)} {selVal == "0" ? "BUSD" : "LP"}{" "} */}
                  {/* {bond.displayUnits} */}
                </>
              )}
            </Typography>
          </div>

          <div className={`data-row`}>
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Trans>You Will Get</Trans>
            </Typography>
            <Typography
              id="bond-value-id"
              className="price-data"
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                `${
                  bond.bondQuote
                    ? customNumberFormat(bond.bondQuote, 4)
                    : 0 || "0"
                } NVB`
              )}
            </Typography>
          </div>

          <div className={`data-row`}>
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Trans>Max You Can Buy</Trans>
            </Typography>
            <Typography
              id="bond-value-id"
              className="price-data"
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                `${customNumberFormat(Number(bond.maxBondPrice), 4) || "0"} NVB`
              )}
            </Typography>
          </div>

          <div className="data-row">
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Trans>ROI</Trans>
            </Typography>
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                <DisplayBondDiscount key={bond.name} bond={bond} />
              )}
            </Typography>
          </div>

          <div className="data-row">
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Trans>Debt Ratio</Trans>
            </Typography>
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                `${trim(bond.debtRatio / 10000000, 2)}%`
              )}
            </Typography>
          </div>

          <div className="data-row">
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Trans>Vesting Term</Trans>
            </Typography>
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {isBondLoading ? <Skeleton width="100px" /> : vestingPeriod()}
            </Typography>
          </div>

          {recipientAddress !== address && (
            <div className="data-row">
              <Typography
                sx={{
                  color: "#EAECEF",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                <Trans>Recipient</Trans>{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#EAECEF",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {isBondLoading ? (
                  <Skeleton width="100px" />
                ) : recipientAddress ? (
                  shorten(recipientAddress)
                ) : (
                  ""
                )}
              </Typography>
            </div>
          )}
        </Box>
      </Slide>
    </Box>
  );
}

export default BondPurchase;
