import React, { useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { useAppSelector, useMobile } from "../../hooks";
import { RecordTableRow, RecordDataCard } from "./RecordRow";
import AccelerateModal from "./AccelerateModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getContributionRecordsByPage,
  getReleaseRecordsByPage,
  releaseClaim,
  releaseClaimAll,
} from "src/slices/ReleaseSlice";
import { useWeb3Context } from "src/hooks/web3Context";
import { getLevelUpBurnAmount } from "src/slices/AccountSlice";
import NoData from "src/assets/images/noData.svg";
import { CustormPagination } from "..";
import { LoadingButton } from "@mui/lab";
import {
  isPendingTxn,
  txnButtonTextGeneralPending,
} from "src/slices/PendingTxnsSlice";
import { INVITE_PER_PAGE } from "src/constants";

const Record = ({ recordData, isStakeRecord = true }: any) => {
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();

  const { isSmallScreen } = useMobile();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [currentIndex, setCurrentIndex] = useState<any>();

  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });
  const contributionTotal = useAppSelector((state) => {
    return state.release.contributionTotal;
  });
  const releaseTotal = useAppSelector((state) => {
    return state.release.releaseTotal;
  });
  const releasePage = useAppSelector((state) => {
    return state.release.releasePage;
  });
  const contributionPage = useAppSelector((state) => {
    return state.release.contributionPage;
  });

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "claim_all_record") ||
      isPendingTxn(pendingTransactions, "release_claim")
    ) {
      return true;
    }

    return false;
  };

  const openAccelerate = async (_record: any, _index: number) => {
    console.log(
      "level handleAccelerate",
      _record,
      _index,
      releasePage,
      contributionPage
    );
    setSelectedRecord(_record);
    const vestingAmt = _record.total - _record.claimed - _record.available;
    console.log("level handleAccelerate ", {
      vestingAmt,
      releasePage,
      contributionPage,
      _index,
    });
    setCurrentIndex(
      isStakeRecord
        ? (releasePage - 1) * INVITE_PER_PAGE + _index
        : (contributionPage - 1) * INVITE_PER_PAGE + _index
    );
    await dispatch(
      getLevelUpBurnAmount({
        provider,
        networkID: chainID,
        amount: vestingAmt,
      })
    );
    setModalOpen(true);
  };

  // const handleAccelerate = (_record: any) => {
  //   console.log("level openAccelerate", _record);
  //   // setLevel(Number(_record.level));
  // };
  console.log("recordData", recordData);
  const handleClose = () => {
    setModalOpen(false);
    // setLevel(0);
    setSelectedRecord({});
  };

  const onClaimAll = async () => {
    console.log("claim all");
    await dispatch(
      releaseClaimAll({
        address,
        provider,
        networkID: chainID,
        records: recordData,
        isStakeRecord,
        total: isStakeRecord ? releaseTotal : contributionTotal,
      })
    );
    if (isStakeRecord) {
      dispatch(
        getReleaseRecordsByPage({
          address,
          provider,
          networkID: chainID,
          page: 1,
        })
      );
    } else {
      dispatch(
        getContributionRecordsByPage({
          address,
          provider,
          networkID: chainID,
          page: 1,
        })
      );
    }
  };

  const count = isStakeRecord
    ? Math.ceil(releaseTotal / INVITE_PER_PAGE)
    : Math.ceil(contributionTotal / INVITE_PER_PAGE);

  const changePage = (event: any, page: any) => {
    if (isStakeRecord) {
      dispatch(
        getReleaseRecordsByPage({
          address,
          provider,
          networkID: chainID,
          page,
        })
      );
    } else {
      dispatch(
        getContributionRecordsByPage({
          address,
          page,
          provider,
          networkID: chainID,
        })
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {/* table */}
      <Box
        sx={{
          width: "100%",
          minHeight: "300px",
          borderRadius: "6px",
          border: "1px solid #2d2d2d",
          background: "#151515",
          px: { xs: "15px", sm: "0" },
          // py: { xs: "20px", sm: "0" },
          pb: "20px !important",
        }}
      >
        {isSmallScreen ? (
          recordData && recordData.length > 0 ? (
            <Box sx={{ mb: "20px" }}>
              {recordData.map((record: any, _index: number) => (
                <RecordDataCard
                  key={_index}
                  record={record}
                  index={
                    isStakeRecord
                      ? (releasePage - 1) * INVITE_PER_PAGE + _index
                      : (contributionPage - 1) * INVITE_PER_PAGE + _index
                  }
                  openAccelerate={openAccelerate}
                  isStakeRecord={isStakeRecord}
                  // handleClaim={releaseClaim}
                />
              ))}
            </Box>
          ) : (
            <Box
              sx={{ width: "100%", height: "330px" }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <img src={NoData} alt="No Data" />
              <Typography
                sx={{
                  pt: 2,
                  color: "#C7C8CC",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                <Trans>No data</Trans>
              </Typography>
            </Box>
          )
        ) : (
          <Box sx={{ minHeight: "300px", mb: "20px" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        color: "#848E9C",
                        fontSize: "16px",
                        fontWeight: "400",
                        borderBottom: "none",
                      }}
                    >
                      <Trans>Time</Trans>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "#848E9C",
                        fontSize: "16px",
                        fontWeight: "400",
                        borderBottom: "none",
                      }}
                    >
                      <Trans>Destruction </Trans> WBNB
                      {/* <Trans>Destruction AVC</Trans> */}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "#848E9C",
                        fontSize: "16px",
                        fontWeight: "400",
                        borderBottom: "none",
                      }}
                    >
                      <Trans>Claimable NVB</Trans>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "#848E9C",
                        fontSize: "16px",
                        fontWeight: "400",
                        borderBottom: "none",
                      }}
                    >
                      <Trans>Pending</Trans>
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        color: "#848E9C",
                        fontSize: "16px",
                        fontWeight: "400",
                        borderBottom: "none",
                      }}
                    >
                      <Trans>Fully Vested</Trans>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#848E9C",
                        fontSize: "16px",
                        fontWeight: "400",
                        borderBottom: "none",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recordData && recordData.length > 0 ? (
                    recordData.map((record: any, _index: number) => (
                      <RecordTableRow
                        key={_index}
                        record={record}
                        index={
                          isStakeRecord
                            ? (releasePage - 1) * INVITE_PER_PAGE + _index
                            : (contributionPage - 1) * INVITE_PER_PAGE + _index
                        }
                        openAccelerate={openAccelerate}
                        isStakeRecord={isStakeRecord}

                        // releaseClaim={releaseClaim}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        sx={{
                          height: "300px",
                          borderBottom: "none",
                        }}
                      >
                        <Box
                          sx={{ width: "100%", height: "100%" }}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                        >
                          <img src={NoData} alt="No Data" />
                          <Typography
                            sx={{
                              pt: 2,
                              color: "#848E9C",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          >
                            <Trans>No data</Trans>
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {recordData && recordData.length > 0 && (
          <Box
            sx={{
              width: "100%",
            }}
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {/* {recordData && recordData.length > 0 && ( */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                mb: 2,
              }}
            >
              <CustormPagination
                siblingCount={isSmallScreen ? 0 : 1}
                boundaryCount={1}
                count={count}
                onChange={changePage}
              />
            </Box>
            {/* )} */}
            {/* )} */}

            {/* {recordData && recordData.length > 0 && ( */}
            <Box
              sx={{
                width: { xs: "100%", sm: "290px" },
                borderRadius: "6px",
                height: "44px",
                border: "1px solid #000",
                background: "#FCD000",
                boxShadow: "-3px 3px 0px 0px #ECAA00",
                mb: "10px",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  isPendingTxn(pendingTransactions, "claim_all_record") ||
                  pendingClaim()
                }
                loading={
                  isPendingTxn(pendingTransactions, "claim_all_record") ||
                  pendingClaim()
                }
                loadingPosition="end"
                onClick={onClaimAll}
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "transparent",
                  color: "#000",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
              >
                {txnButtonTextGeneralPending(
                  pendingTransactions,
                  "claim_all_record",
                  t`Claim all`
                )}
              </LoadingButton>
            </Box>
            {/* )} */}
          </Box>
        )}
      </Box>

      <AccelerateModal
        open={modalOpen}
        handleClose={handleClose}
        // handleAccelerate={handleAccelerate}
        record={selectedRecord}
        isStakeRecord={isStakeRecord}
        index={currentIndex}
      />
    </Box>
  );
};

export default Record;
