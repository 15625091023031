import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses, INVITE_PER_PAGE } from "../constants";
import ierc20AbiJson from "../abi/IERC20.json";
import sOHMv2Json from "../abi/sOhmv2.json";
import OlympusStakingABIJson from "../abi/OlympusStakingv2.json";
import ReleasePoolABIJson from "../abi/ReleasePool.json";
import ReleasePoolHelperABIJson from "../abi/ReleasePoolHelper.json";
import RewardDistributor from "../abi/RewardDistributor.json";

import { getTokenDecimals, setAll, toBN } from "../helpers";
import { t } from "@lingui/macro";
import { error, info } from "../slices/MessagesSlice";
import { durationList } from "src/constants";
import dayjs from "dayjs";
import {
  clearPendingTxn,
  fetchPendingTxns,
  getStakingTypeText,
} from "./PendingTxnsSlice";

import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "src/store";
import {
  IJsonRPCError,
  IBaseAddressAsyncThunk,
  ICalcUserBondDetailsAsyncThunk,
} from "./interfaces";
import {
  FuseProxy,
  IERC20,
  SOhmv2,
  WsOHM,
  OlympusStakingv2,
} from "src/typechain";
import { fetchAccountSuccess } from "./AccountSlice";

const ierc20Abi = ierc20AbiJson.abi;
const sOHMv2 = sOHMv2Json.abi;
const OlympusStakingABI = OlympusStakingABIJson.abi;
const ReleasePoolABI = ReleasePoolABIJson.abi;
const ReleasePoolHelperABI = ReleasePoolHelperABIJson.abi;
const RewardDistributorAbi = RewardDistributor.abi;

export const getReleaseRecordsByPage = createAsyncThunk(
  "release/getReleaseRecordsByPage",
  async ({
    address,
    networkID,
    provider,
    page,
    limit = INVITE_PER_PAGE,
  }: any) => {
    try {
      const ReleasePoolContract = new ethers.Contract(
        addresses[networkID].stakingReleasePool as string,
        ReleasePoolABI,
        provider
      ) as any;
      const releaseLength = await ReleasePoolContract.releaseInfoLength(
        address
      );
      let records: any[] = [];
      const start = (page - 1) * limit;
      // const end = Math.min(page * limit, Number(releaseLength));
      const pageLimit = Math.min(limit, Number(releaseLength) - start);

      console.log("[releaseLength]", {
        releaseLength,
        page,
        limit,
        // pageLimit: page * limit,
        start,
        pageLimit,
      });

      records = await ReleasePoolContract.releaseInfoBetween(
        address,
        start,
        pageLimit
      );
      // if (Number(releaseLength) >= page * limit) {
      //   records = await ReleasePoolContract.releaseInfoBetween(
      //     address,
      //     (page - 1) * limit,
      //     page * limit
      //   );
      // } else {
      //   records = await ReleasePoolContract.releaseInfoBetween(
      //     address,
      //     (page - 1) * limit,
      //     (page - 1) * limit + Number(releaseLength)
      //   );
      // }
      console.log(
        "[getReleaseRecordsByPage releaseLength record]",
        records,
        records[0],
        records[1]
      );
      records = records[0].map((record: any, index: number) => {
        // console.log("release item", record);
        const total = Number(ethers.utils.formatUnits(record.total, "9"));
        if (total != 0) {
          return {
            ...record,
            burnedAmt: ethers.utils.formatEther(record.burnedAmt),
            total: total,
            pending: ethers.utils.formatUnits(
              record.total.sub(record.claimed),
              "9"
            ),
            // duration: durationList[String(record.level)],
            duration: record.duration,
            available: ethers.utils.formatUnits(records[1][index], "9"),
            // available:
            //   vested - Number(ethers.utils.formatUnits(record.claimed, "9")),
            claimed: ethers.utils.formatUnits(record.claimed, "9"),
          };
        }
      });
      console.log("getReleaseRecordsByPage release filter before", records);
      records = records.filter((_record: any) => _record != undefined);
      console.log("getReleaseRecordsByPage release filter after", records);

      return {
        releaseRecords: records,
        releasePage: page,
        releaseTotal: Number(releaseLength),
      };
    } catch (error) {
      console.log("getAccountReleaseRecords error", error);
    }
  }
);

export const getContributionRecordsByPage = createAsyncThunk(
  "release/getContributionRecordsByPage",
  async ({
    address,
    networkID,
    provider,
    page,
    limit = INVITE_PER_PAGE,
  }: any) => {
    try {
      const contributionReleasePoolContract = new ethers.Contract(
        addresses[networkID].contributionReleasePool as string,
        ReleasePoolABI,
        provider
      ) as any;
      const releaseLength = await contributionReleasePoolContract.releaseInfoLength(
        address
      );
      console.log("[releaseLength]", releaseLength);
      let records: any[] = [];
      if (Number(releaseLength) >= page * limit) {
        records = await contributionReleasePoolContract.releaseInfoBetween(
          address,
          (page - 1) * limit,
          page * limit
        );
      } else {
        records = await contributionReleasePoolContract.releaseInfoBetween(
          address,
          (page - 1) * limit,
          (page - 1) * limit + Number(releaseLength)
        );
      }
      console.log("[releaseLength record]", records);
      records = records[0].map((record: any, index: number) => {
        // console.log("release item", record);
        const total = Number(ethers.utils.formatUnits(record.total, "9"));
        if (total != 0) {
          return {
            ...record,
            burnedAmt: ethers.utils.formatEther(record.burnedAmt),
            total: total,
            pending: ethers.utils.formatUnits(
              record.total.sub(record.claimed),
              "9"
            ),
            // duration: durationList[String(record.level)],
            duration: record.duration,
            available: ethers.utils.formatUnits(records[1][index], "9"),
            // available:
            //   vested - Number(ethers.utils.formatUnits(record.claimed, "9")),
            claimed: ethers.utils.formatUnits(record.claimed, "9"),
          };
        }
      });
      records = records.filter((_record: any) => _record != undefined);

      return {
        contributionRecords: records,
        contributionPage: page,
        contributionTotal: Number(releaseLength),
      };
    } catch (error) {
      console.log("getContributionRecordsByPage error", error);
    }
  }
);

export const releaseClaim = createAsyncThunk(
  "release/releaseClaim",
  async (
    { id, provider, address, networkID, isStakeRecord }: any,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    // const signer = provider.getSigner();
    const signer = provider;
    const ReleasePoolContract = new ethers.Contract(
      isStakeRecord
        ? (addresses[networkID].stakingReleasePool as string)
        : (addresses[networkID].contributionReleasePool as string),
      ReleasePoolABI,
      signer
    ) as any;

    let releaseTx;
    try {
      const estimateGas = await ReleasePoolContract.estimateGas.claim(
        address,
        id
      );
      releaseTx = await ReleasePoolContract.claim(address, id, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const pendingTxnType = "release_claim";
      dispatch(
        fetchPendingTxns({
          txnHash: releaseTx.hash,
          text: "claim",
          type: pendingTxnType,
        })
      );
      await releaseTx.wait();
    } catch (e) {
      const rpcError = e as IJsonRPCError;
      if (
        rpcError.code === -32603 &&
        rpcError.message.indexOf("ds-math-sub-underflow") >= 0
      ) {
        dispatch(
          error(
            "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"
          )
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (releaseTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(releaseTx.hash));
      }
    }
  }
);

export const releaseClaimAll = createAsyncThunk(
  "release/releaseClaimAll",
  async (
    { provider, address, networkID, records, isStakeRecord, total }: any,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    // const signer = provider.getSigner();
    const signer = provider;
    const ReleasePoolHelperContract = new ethers.Contract(
      addresses[networkID].releasePoolHelper as string,
      ReleasePoolHelperABI,
      signer
    ) as any;

    // let idsArray: any[] = [];

    // if (records) {
    //   records.forEach((_record: any, index: number) => {
    //     console.log("recordData _record", _record as any);
    //     idsArray.push(String(index));
    //   });
    // }
    // idsArray = idsArray.reverse();
    // console.log(
    //   "recordData",
    //   idsArray,
    //   records,
    //   isStakeRecord
    //     ? (addresses[networkID].stakingReleasePool as string)
    //     : (addresses[networkID].contributionReleasePool as string),
    //   address,
    //   idsArray
    // );
    let claimAllTx;
    try {
      const estimateGas = await ReleasePoolHelperContract.estimateGas.batchClaimWithLimit(
        isStakeRecord
          ? (addresses[networkID].stakingReleasePool as string)
          : (addresses[networkID].contributionReleasePool as string),
        address,
        total
      );
      console.log("recordData estimateGas", estimateGas);
      claimAllTx = await ReleasePoolHelperContract.batchClaimWithLimit(
        isStakeRecord
          ? (addresses[networkID].stakingReleasePool as string)
          : (addresses[networkID].contributionReleasePool as string),
        address,
        total,
        {
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        }
      );
      // const estimateGas = await ReleasePoolHelperContract.estimateGas.batchClaim(
      //   isStakeRecord
      //     ? (addresses[networkID].stakingReleasePool as string)
      //     : (addresses[networkID].contributionReleasePool as string),
      //   address,
      //   idsArray
      // );
      // console.log("recordData estimateGas", estimateGas);
      // claimAllTx = await ReleasePoolHelperContract.batchClaim(
      //   isStakeRecord
      //     ? (addresses[networkID].stakingReleasePool as string)
      //     : (addresses[networkID].contributionReleasePool as string),
      //   address,
      //   idsArray,
      //   {
      //     gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      //   }
      // );
      const pendingTxnType = "claim_all_record";
      dispatch(
        fetchPendingTxns({
          txnHash: claimAllTx.hash,
          text: "claim_all",
          type: pendingTxnType,
        })
      );
      await claimAllTx.wait();
    } catch (e) {
      const rpcError = e as IJsonRPCError;
      if (
        rpcError.code === -32603 &&
        rpcError.message.indexOf("ds-math-sub-underflow") >= 0
      ) {
        dispatch(
          error(
            "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"
          )
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (claimAllTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(claimAllTx.hash));
      }
    }
  }
);

export const releaseLevelUP = createAsyncThunk(
  "release/releaseLevelUP",
  async (
    { id, provider, networkID, level, burningAmt, isStakeRecord }: any,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // console.log(
    //   "releaseLevelUP",
    //   id,
    //   ethers.utils.parseUnits(burningAmt, "9"),
    //   level
    // );
    // const signer = provider.getSigner();
    // console.log("releaseLevelUP", provider, id, level, burningAmt);
    const amount = ethers.utils.parseUnits(String(burningAmt), "18");
    console.log("releaseLevelUP", { id, amount, level });
    const signer = provider;
    const ReleasePoolContract = new ethers.Contract(
      isStakeRecord
        ? (addresses[networkID].stakingReleasePool as string)
        : (addresses[networkID].contributionReleasePool as string),
      ReleasePoolABI,
      signer
    ) as any;

    let releaseTx;
    try {
      const estimateGas = await ReleasePoolContract.estimateGas.levelUp(
        id,
        amount,
        level
      );
      console.log("estimateGas", estimateGas);
      releaseTx = await ReleasePoolContract.levelUp(id, amount, level, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const pendingTxnType = "release_level_up";
      dispatch(
        fetchPendingTxns({
          txnHash: releaseTx.hash,
          text: "level up",
          type: pendingTxnType,
        })
      );
      await releaseTx.wait();
    } catch (e) {
      const rpcError = e as IJsonRPCError;
      if (
        rpcError.code === -32603 &&
        rpcError.message.indexOf("ds-math-sub-underflow") >= 0
      ) {
        dispatch(
          error(
            "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"
          )
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (releaseTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(releaseTx.hash));
      }
    }
  }
);

export const contributionBurn = createAsyncThunk(
  "release/contributionBurn",
  async ({ provider, networkID, burnAmt }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    const signer = provider;
    const RewardDistributorContract = new ethers.Contract(
      addresses[networkID].rewardDistributor as string,
      RewardDistributorAbi,
      signer
    ) as any;

    let burnTx;
    const burningAmount = ethers.utils.parseUnits(burnAmt, "9");
    console.log("burningAmount", burningAmount);
    try {
      const estimateGas = await RewardDistributorContract.estimateGas.burn(
        burningAmount
      );
      burnTx = await RewardDistributorContract.burn(burningAmount, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const pendingTxnType = "contribution_burn";
      dispatch(
        fetchPendingTxns({
          txnHash: burnTx.hash,
          text: "burn",
          type: pendingTxnType,
        })
      );
      await burnTx.wait();
    } catch (e) {
      const rpcError = e as IJsonRPCError;
      if (
        rpcError.code === -32603 &&
        rpcError.message.indexOf("ds-math-sub-underflow") >= 0
      ) {
        dispatch(
          error(
            "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"
          )
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (burnTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(burnTx.hash));
      }
    }
  }
);

export const contributionClaim = createAsyncThunk(
  "release/contributionClaim",
  async ({ provider, networkID, burnAmt, level }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    console.log("claimTeamReward", { provider, networkID, burnAmt, level });
    const signer = provider;
    const RewardDistributorContract = new ethers.Contract(
      addresses[networkID].rewardDistributor as string,
      RewardDistributorAbi,
      signer
    ) as any;

    let claimTx;
    const burningAmount = ethers.utils.parseUnits(burnAmt, "18");
    console.log("claimTeamReward burningAmount", burningAmount);
    try {
      const estimateGas = await RewardDistributorContract.estimateGas.claim(
        burningAmount,
        level
      );
      claimTx = await RewardDistributorContract.claim(burningAmount, level, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const pendingTxnType = "contribution_claim";
      dispatch(
        fetchPendingTxns({
          txnHash: claimTx.hash,
          text: "burn",
          type: pendingTxnType,
        })
      );
      await claimTx.wait();
    } catch (e) {
      const rpcError = e as IJsonRPCError;
      if (
        rpcError.code === -32603 &&
        rpcError.message.indexOf("ds-math-sub-underflow") >= 0
      ) {
        dispatch(
          error(
            "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"
          )
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (claimTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(claimTx.hash));
      }
    }
  }
);

export const approveForDistributor = createAsyncThunk(
  "release/approveForDistributor",
  async ({ provider, networkID, address, token }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    const signer = provider;
    const ohmContract = new ethers.Contract(
      addresses[networkID].OHM_ADDRESS as string,
      ierc20Abi,
      signer
    ) as IERC20;
    const bTokenContract = new ethers.Contract(
      addresses[networkID].WBNB_ADDRESS as string,
      ierc20Abi,
      signer
    ) as IERC20;
    // const bTokenContract = new ethers.Contract(
    //   addresses[networkID].bToken as string,
    //   ierc20Abi,
    //   signer
    // ) as IERC20;
    let approveTx;
    try {
      // won't run if stakeAllowance > 0
      if (token === "ohm") {
        const estimateGas = await ohmContract.estimateGas.approve(
          addresses[networkID].rewardDistributor,
          ethers.utils.parseUnits("1000000000", "gwei").toString()
        );

        approveTx = await ohmContract.approve(
          addresses[networkID].rewardDistributor,
          ethers.utils.parseUnits("1000000000", "gwei").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      } else if (token == "bToken") {
        const estimateGas = await bTokenContract.estimateGas.approve(
          addresses[networkID].contributionReleasePool,
          ethers.utils.parseUnits("1000000000", "18").toString()
        );

        approveTx = await bTokenContract.approve(
          addresses[networkID].contributionReleasePool,
          ethers.utils.parseUnits("1000000000", "18").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      }

      const text = "Approve ohm contribution";
      const pendingTxnType = "contribution_approve";
      if (approveTx) {
        dispatch(
          fetchPendingTxns({
            txnHash: approveTx.hash,
            text,
            type: pendingTxnType,
          })
        );

        await approveTx.wait();
      }
    } catch (e) {
      // dispatch(error((e as IJsonRPCError).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
    const rewardDistributorAllowance = await ohmContract.allowance(
      address,
      addresses[networkID].rewardDistributor
    );
    const bTokenrewardDistributorAllowance = await bTokenContract.allowance(
      address,
      addresses[networkID].contributionReleasePool
    );
    console.log("rewardDistributorAllowance", rewardDistributorAllowance);
    return dispatch(
      fetchAccountSuccess({
        rewardDistributorAllowance: ethers.utils.formatUnits(
          rewardDistributorAllowance,
          "9"
        ),
        bTokenrewardDistributorAllowance: ethers.utils.formatUnits(
          bTokenrewardDistributorAllowance,
          "9"
        ),
      })
    );
  }
);

const initialState: any = {
  releaseRecords: null,
  releasePage: 1,
  releaseTotal: 0,
  contributionRecords: null,
  contributionPage: 1,
  contributionTotal: 0,
};

const releaseSlice = createSlice({
  name: "release",
  initialState,
  reducers: {
    // fetchAccountSuccess(state, action) {
    //   setAll(state, action.payload);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReleaseRecordsByPage.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReleaseRecordsByPage.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getReleaseRecordsByPage.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getContributionRecordsByPage.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContributionRecordsByPage.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getContributionRecordsByPage.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default releaseSlice.reducer;

// export const { fetchAccountSuccess } = releaseSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, (account) => account);
