import { Button, Typography, Box } from "@mui/material";
import { useWeb3Context } from "src/hooks/web3Context";
import { Trans } from "@lingui/macro";
import { useMobile } from "src/hooks";
const ConnectButton = () => {
  const { connect } = useWeb3Context();
  const { isSmallScreen } = useMobile();
  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "240px" },
        height: "40px",
        borderRadius: "6px",
        border: "1px solid #000",
        background: "#fcd000",
        boxShadow: "-3px 3px 0px 0px #ECAA00",
        "&:hover": {
          transform: "scale(1.02)",
        },
      }}
    >
      <Button
        variant="contained"
        className="connect-button"
        onClick={connect}
        sx={{
          width: "100%",
          height: "100%",
          background: "transparent",
          "&:hover": {
            background: "transparent",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: "#000",
            fontWeight: "500",
          }}
        >
          <Trans>Connect Wallet</Trans>
        </Typography>
      </Button>
    </Box>
  );
};

export default ConnectButton;
