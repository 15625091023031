import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Typography, Box, Slide } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { redeemBond } from "../../slices/BondSlice";
import { useWeb3Context } from "src/hooks/web3Context";
import {
  trim,
  secondsUntilBlock,
  prettifySeconds,
  prettyVestingPeriod,
} from "../../helpers";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@mui/material";
import { DisplayBondDiscount } from "./Bond.jsx";
import ConnectButton from "../../components/ConnectButton";

function BondRedeem({ bond }) {
  // const { bond: bondName } = bond;
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();

  const isBondLoading = useSelector((state) => state.bonding.loading ?? true);

  const currentBlock = useSelector((state) => {
    return state.app.currentBlock;
  });
  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });
  const bondingState = useSelector((state) => {
    return state.bonding && state.bonding[bond.name];
  });
  const bondDetails = useSelector((state) => {
    return state.account.bonds && state.account.bonds[bond.name];
  });

  async function onRedeem({ autostake }) {
    await dispatch(
      redeemBond({ address, bond, networkID: chainID, provider, autostake })
    );
  }
  const vestingTime = () => {
    console.log("bondMaturationBlock", bond.bondMaturationBlock, currentBlock);
    return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
  };

  const vestingPeriod = () => {
    // if (currentBlock && bondingState && bondingState.vestingTerm) {
    const vestingBlock =
      parseInt(currentBlock) + parseInt(bondingState.vestingTerm);
    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    return prettifySeconds(seconds, "day");
    // } else {
    //   return null;
    // }
  };

  // useEffect(() => {
  //   console.log(bond);
  //   console.log(bondingState);
  //   console.log(bondDetails);
  // }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {!address ? (
          <ConnectButton />
        ) : (
          <>
            <Button
              variant="contained"
              id="bond-claim-btn"
              className="transaction-button"
              fullWidth
              disabled={
                isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name) ||
                bond.pendingPayout == 0.0
              }
              onClick={() => {
                onRedeem({ autostake: false });
              }}
              sx={{
                borderRadius: "30px",
                border: "1.3px solid #B50C05",
                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                boxShadow:
                  "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                "&:hover": {
                  background:
                    "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                },
              }}
            >
              {txnButtonText(
                pendingTransactions,
                "redeem_bond_" + bond.name,
                t`Claim`
              )}
            </Button>
            <Button
              variant="contained"
              id="bond-claim-autostake-btn"
              className="transaction-button"
              fullWidth
              disabled={
                isPendingTxn(
                  pendingTransactions,
                  "redeem_bond_" + bond.name + "_autostake"
                ) || bond.pendingPayout == 0.0
              }
              onClick={() => {
                onRedeem({ autostake: true });
              }}
              sx={{
                borderRadius: "30px",
                border: "1.3px solid #B50C05",
                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                boxShadow:
                  "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                "&:hover": {
                  background:
                    "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                },
              }}
            >
              {txnButtonText(
                pendingTransactions,
                "redeem_bond_" + bond.name + "_autostake",
                t`Claim and Autostake`
              )}
            </Button>
          </>
        )}
      </Box>
      <Slide
        direction="right"
        in={true}
        mountOnEnter
        unmountOnExit
        {...{ timeout: 533 }}
      >
        <Box className="bond-data">
          <div className="data-row">
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              <Trans>Pending Rewards</Trans>
            </Typography>
            <Typography
              className="price-data"
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                `${trim(bond.interestDue, 4)} OHM`
              )}
            </Typography>
          </div>
          <div className="data-row">
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              <Trans>Claimable Rewards</Trans>
            </Typography>
            <Typography
              id="claimable"
              className="price-data"
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                `${trim(bond.pendingPayout, 4)} OHM`
              )}
            </Typography>
          </div>
          <div className="data-row">
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              <Trans>Time until fully vested</Trans>
            </Typography>
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
              className="price-data"
            >
              {isBondLoading ? <Skeleton width="100px" /> : vestingTime()}
            </Typography>
          </div>

          <div className="data-row">
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              <Trans>ROI</Trans>
            </Typography>
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                <DisplayBondDiscount key={bond.name} bond={bond} />
              )}
            </Typography>
          </div>

          <div className="data-row">
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              <Trans>Debt Ratio</Trans>
            </Typography>
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                `${trim(bond.debtRatio / 10000000, 2)}%`
              )}
            </Typography>
          </div>

          <div className="data-row">
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              <Trans>Vesting Term</Trans>
            </Typography>
            <Typography
              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700" }}
            >
              {isBondLoading ? <Skeleton width="100px" /> : vestingPeriod()}
            </Typography>
          </div>
        </Box>
      </Slide>
    </Box>
  );
}

export default BondRedeem;
