import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import ierc20ABIJson from "../abi/IERC20.json";
import { t } from "@lingui/macro";

import RouterABIJson from "../abi/RouterContract.json";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { error, info } from "./MessagesSlice";
import { IERC20, OlympusStakingv2, StakingHelper } from "src/typechain";
import {
  IActionValueAsyncThunk,
  IChangeApprovalAsyncThunk,
  IJsonRPCError,
} from "./interfaces";
import { setAll, formatMoney, getTokenDecimals } from "../helpers";
import { RootState } from "src/store";
import {
  clearPendingTxn,
  fetchPendingTxns,
  getStakingTypeText,
} from "./PendingTxnsSlice";
import { getBalances } from "./AccountSlice";

const ierc20ABI = ierc20ABIJson.abi;
const RouterABI = RouterABIJson.abi;

export const approveSwap = createAsyncThunk(
  "swap/approveSwap",
  async ({ provider, address, networkID, topToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    console.log("approve thunk");
    const signer = provider;
    const ohmContract = new ethers.Contract(
      addresses[networkID].OHM_ADDRESS as string,
      ierc20ABI,
      signer
    ) as IERC20;

    const USDTContract = new ethers.Contract(
      addresses[networkID].USDT_ADDRESS as string,
      ierc20ABI,
      signer
    ) as IERC20;
    const bTokenContract = new ethers.Contract(
      addresses[networkID].bToken as string,
      ierc20ABI,
      signer
    ) as IERC20;
    const wbnbContract = new ethers.Contract(
      addresses[networkID].WBNB_ADDRESS as string,
      ierc20ABI,
      signer
    ) as IERC20;
    console.log("approve thunk 1");

    let approveTx;
    try {
      if (topToken == "NVB") {
        const estimateGas = await ohmContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "9").toString()
        );

        approveTx = await ohmContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "9").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      } else if (topToken == "USDT") {
        const estimateGas = await USDTContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000").toString()
        );
        approveTx = await USDTContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000").toString(),

          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      } else if (topToken == "AVC") {
        const estimateGas = await bTokenContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "18").toString()
        );
        approveTx = await bTokenContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "18").toString(),

          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      } else if (topToken == "WBNB") {
        const estimateGas = await wbnbContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000", "18").toString()
        );
        approveTx = await wbnbContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000", "18").toString(),

          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      }
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_swap";
      if (approveTx) {
        dispatch(
          fetchPendingTxns({
            txnHash: approveTx.hash,
            text,
            type: pendingTxnType,
          })
        );
        await approveTx.wait();
        return;
        // return {
        //   busdAllowance: "2000",
        // };
      }
    } catch (e) {
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  }
);

export const getAmountsOut = createAsyncThunk(
  "swap/getAmountsOut",
  async (
    {
      provider,
      address,
      networkID,
      amountsIn,
      type,
      topToken,
      bottomToken,
    }: any,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    try {
      const signer = provider;
      const routerContract = new ethers.Contract(
        addresses[networkID].pancakeRouter as string,
        RouterABI,
        signer
      ) as any;
      const decimal = topToken != "NVB" ? "18" : "9";
      const amountIn = ethers.utils.parseUnits(amountsIn, decimal);
      console.log("getAmountsOut amountIn", networkID, amountIn, topToken);
      let amountsOut;

      let addressArr: any[] = [];
      if (topToken == "NVB") {
        if (bottomToken == "USDT") {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
          ];
        } else if (bottomToken == "WBNB") {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`WBNB_ADDRESS`],
          ];
        } else {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`bToken`],
          ];
        }
      } else if (bottomToken == "NVB") {
        if (topToken == "USDT") {
          addressArr = [
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        } else if (topToken === "WBNB") {
          addressArr = [
            addresses[networkID][`WBNB_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        } else {
          addressArr = [
            addresses[networkID][`bToken`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        }
      } else {
        if (topToken == "USDT") {
          if (bottomToken === "WBNB") {
            addressArr = [
              addresses[networkID][`USDT_ADDRESS`],
              addresses[networkID][`WBNB_ADDRESS`],
            ];
          } else {
            addressArr = [
              addresses[networkID][`USDT_ADDRESS`],
              addresses[networkID][`bToken`],
            ];
          }
        } else if (bottomToken == "USDT") {
          if (topToken == "WBNB") {
            addressArr = [
              addresses[networkID][`WBNB_ADDRESS`],
              addresses[networkID][`USDT_ADDRESS`],
            ];
          } else {
            addressArr = [
              addresses[networkID][`bToken`],
              addresses[networkID][`USDT_ADDRESS`],
            ];
          }
        }
      }
      console.log("getAmountsOut addressArr", addressArr, routerContract);
      const amounts = await routerContract.getAmountsOut(amountIn, addressArr);
      console.log("avc getAmountsOut amounts", amounts);
      const amount = amounts[amounts.length - 1];

      const decimalOut = bottomToken == "NVB" ? "9" : "18";

      amountsOut = ethers.utils.formatUnits(String(amount), decimalOut);
      console.log("getAmountsOut amountsOut", amountsOut);
      return {
        amountsOut,
      };
    } catch (error) {
      console.log("getAmountsOut error", error);
    }
  }
);

export const getAmountsIn = createAsyncThunk(
  "swap/getAmountsIn",
  async (
    {
      provider,
      address,
      networkID,
      amountsOut,
      type,
      topToken,
      bottomToken,
    }: any,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    try {
      const signer = provider;
      const routerContract = new ethers.Contract(
        addresses[networkID].pancakeRouter as string,
        RouterABI,
        signer
      ) as any;
      const decimal = bottomToken != "NVB" ? "18" : "9";
      const amountOut = ethers.utils.parseUnits(amountsOut, decimal);
      console.log("getAmountsIn amountIn", networkID, amountOut, topToken);
      let amountsIn;

      let addressArr: any[] = [];
      if (topToken == "NVB") {
        if (bottomToken == "USDT") {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
          ];
        } else if (bottomToken == "WBNB") {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`WBNB_ADDRESS`],
          ];
        } else {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`bToken`],
          ];
        }
      } else if (bottomToken == "NVB") {
        if (topToken == "USDT") {
          addressArr = [
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        } else if (topToken === "WBNB") {
          addressArr = [
            addresses[networkID][`WBNB_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        } else {
          addressArr = [
            addresses[networkID][`bToken`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        }
      } else {
        if (topToken == "USDT") {
          if (bottomToken === "WBNB") {
            addressArr = [
              addresses[networkID][`USDT_ADDRESS`],
              addresses[networkID][`WBNB_ADDRESS`],
            ];
          } else {
            addressArr = [
              addresses[networkID][`USDT_ADDRESS`],
              addresses[networkID][`bToken`],
            ];
          }
        } else if (bottomToken == "USDT") {
          if (topToken == "WBNB") {
            addressArr = [
              addresses[networkID][`WBNB_ADDRESS`],
              addresses[networkID][`USDT_ADDRESS`],
            ];
          } else {
            addressArr = [
              addresses[networkID][`bToken`],
              addresses[networkID][`USDT_ADDRESS`],
            ];
          }
        }
      }
      console.log("getAmountsIn addressArr", {
        amountOut,
        addressArr,
        topToken,
        bottomToken,
      });
      const amounts = await routerContract.getAmountsIn(amountOut, addressArr);
      console.log("avc getAmountsIn amounts", amounts);
      const amount = amounts[0];

      const decimalIn = topToken == "NVB" ? "9" : "18";

      amountsIn = ethers.utils.formatUnits(String(amount), decimalIn);
      console.log("getAmountsIn amountsIn", amountsIn);
      return {
        amountsIn,
      };
    } catch (error) {
      console.log("getAmountsIn error", error);
    }
  }
);

export const clearAmount = createAsyncThunk("swap/clearAmount", async () => {
  return {
    amountsOut: "",
    amountsIn: "",
  };
});

export const getSelectToken = createAsyncThunk(
  "swap/getSelectToken",
  async (
    { provider, address, networkID, topToken, bottomToken }: any,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider;
    let topBal, bottomBal;
    let tToken =
      topToken === "NVB" ? "OHM" : topToken === "USDT" ? "USDT" : topToken;
    let bToken =
      bottomToken === "NVB"
        ? "OHM"
        : bottomToken === "USDT"
        ? "USDT"
        : bottomToken;

    console.log(
      "topToken",
      tToken,
      bToken,
      topToken,
      bottomToken,
      addresses[networkID][`${tToken}_ADDRESS`],
      addresses[networkID][`${bToken}_ADDRESS`]
    );

    const topTokenContract = new ethers.Contract(
      addresses[networkID][`${tToken}_ADDRESS`] as string,
      ierc20ABI,
      signer
    ) as IERC20;

    const bottomTokenContract = new ethers.Contract(
      addresses[networkID][`${bToken}_ADDRESS`] as string,
      ierc20ABI,
      signer
    ) as IERC20;
    console.log("topToken", topTokenContract, bottomTokenContract);
    const [tDecimals, bDecimals] = await Promise.all([
      getTokenDecimals(
        addresses[networkID][`${tToken}_ADDRESS`] as string,
        networkID
      ),
      getTokenDecimals(
        addresses[networkID][`${bToken}_ADDRESS`] as string,
        networkID
      ),
    ]);
    console.log("topToken", tDecimals, bDecimals, address);
    try {
      topBal = await topTokenContract.balanceOf(address);
      console.log("topToken topBal", topBal);
      bottomBal = await bottomTokenContract.balanceOf(address);
      console.log("topToken topBal", topBal, bottomBal);
      topBal = ethers.utils.formatUnits(topBal, tDecimals);
      bottomBal = ethers.utils.formatUnits(bottomBal, bDecimals);
      console.log("topToken topBal", topBal, bottomBal);
      return { topBal, bottomBal };
    } catch (error) {
      console.log("topToken err", error);
    }
  }
);

export const swapToken = createAsyncThunk(
  "swap/swapToken",
  async (
    {
      provider,
      address,
      networkID,
      amountsIn,
      amountsOut,
      type,
      topToken,
      bottomToken,
    }: any,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    // console.log(provider, address, networkID, amountsIn, amountsOut, type, "yt");

    try {
      const slippage: number = Number(localStorage.getItem("slippage")) || 0.1;
      console.log("avc slippage", slippage);
      const signer = provider;
      const amountIn = ethers.utils.parseUnits(
        amountsIn,
        topToken === "NVB" ? "9" : "18"
      );

      const amountOut = amountsOut * (1 - slippage / 100);
      console.log("amountOut", amountOut);
      // const amountOutMin = ethers.utils.parseUnits(String(amountOut), bottomToken == "NVB" ? "9" : "18");
      const amountOutMin = ethers.utils.parseUnits(
        String(amountOut.toFixed(6)),
        bottomToken == "NVB" ? "9" : "18"
      );
      const routerContract = new ethers.Contract(
        addresses[networkID].pancakeRouter as string,
        RouterABI,
        signer
      ) as any;
      let addressArr: any[] = [];
      if (topToken == "NVB") {
        if (bottomToken == "USDT") {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
          ];
        } else if (bottomToken == "WBNB") {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`WBNB_ADDRESS`],
          ];
        } else {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`bToken`],
          ];
        }
      } else if (bottomToken == "NVB") {
        if (topToken == "USDT") {
          addressArr = [
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        } else if (topToken === "WBNB") {
          addressArr = [
            addresses[networkID][`WBNB_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        } else {
          addressArr = [
            addresses[networkID][`bToken`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        }
      } else {
        if (topToken == "USDT") {
          if (bottomToken === "WBNB") {
            addressArr = [
              addresses[networkID][`USDT_ADDRESS`],
              addresses[networkID][`WBNB_ADDRESS`],
            ];
          } else {
            addressArr = [
              addresses[networkID][`USDT_ADDRESS`],
              addresses[networkID][`bToken`],
            ];
          }
        } else {
          addressArr = [
            addresses[networkID][`WBNB_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
          ];
        }
      }

      let swapTx;
      console.log("swap params", {
        amountIn,
        amountOutMin,
        addressArr,
      });
      const deadline: number = Number(localStorage.getItem("deadline")) || 5;
      try {
        swapTx = await routerContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
          amountIn,
          amountOutMin,
          addressArr,
          address,
          Date.now() + 1000 * 60 * deadline
        );

        const text = "Swap";
        const pendingTxnType = "Swap_TOKEN";
        if (swapTx) {
          dispatch(
            fetchPendingTxns({
              txnHash: swapTx.hash,
              text,
              type: pendingTxnType,
            })
          );
          await swapTx.wait();
          await dispatch(getBalances({ address, provider, networkID }));
          return;
        }
      } catch (e) {
        if ((e as any).code == "ACTION_REJECTED") {
          dispatch(error(t`User denied transaction signature.`));
          // dispatch(error((e as any).message));
        } else if (e == "cancel") {
          dispatch(error(t`User denied transaction signature.`));
        } else if (
          (e as any).reason.indexOf("INSUFFICIENT_OUTPUT_AMOUNT") > -1
        ) {
          console.log("reason", (e as any).reason);
          console.log("message", (e as any).message);
          console.log("data", (e as any).data);
          // "execution reverted: PancakeRouter: "
          dispatch(error(t`Try increasing your slippage tolerance.`));
        } else {
          // dispatch(error((e as any).message));
          dispatch(
            error(
              (e as any).reason ||
                (e as any).message ||
                (e as any).data ||
                (e as any)
            )
          );
        }
        return;
      } finally {
        if (swapTx) {
          dispatch(clearPendingTxn(swapTx.hash));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  }
);

export interface ISwapSlice {
  busdAllowance: string;
  loading: boolean;
  isWhiteeListed: boolean;
  showBoughtSuccessful: boolean;
  totalAmount: string;
  remaining: string;
  totalSubscription: string;
  axphAllowance: string;
  aXPHBalance: string;
  XPHBalance: string;
  isSubscriptionSuccessful: boolean;
  amountsIn: string;
  amountsOut: string;
  topBal: string;
  bottomBal: string;
}

const initialState: ISwapSlice = {
  busdAllowance: "",
  loading: false,
  isWhiteeListed: false,
  showBoughtSuccessful: false,
  isSubscriptionSuccessful: false,
  totalAmount: "",
  remaining: "",
  totalSubscription: "",
  axphAllowance: "",
  aXPHBalance: "",
  XPHBalance: "",
  amountsIn: "",
  amountsOut: "",
  topBal: "",
  bottomBal: "",
};

const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(swapToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(swapToken.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(swapToken.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsOut.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAmountsOut.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsOut.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAmountsIn.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsIn.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getSelectToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSelectToken.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getSelectToken.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(clearAmount.pending, (state) => {
        state.loading = true;
      })
      .addCase(clearAmount.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(clearAmount.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

export default swapSlice.reducer;
const baseInfo = (state: RootState) => state.swap;
export const getIdoState = createSelector(baseInfo, (swap) => swap);
